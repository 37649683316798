<script setup lang="ts">
import { useRouter } from 'vue-router';
import DeskButton from '@/components/shared/DeskButton/DeskButton.vue';
import { RouteNames } from '@/router/RouteNames';

const deskSelectionRoute = useRouter().resolve({
  name: RouteNames.DESK_SELECT,
});
</script>
<template>
  <div class="mb-3">
    <DeskButton :to="deskSelectionRoute">Go to desk selection</DeskButton>
  </div>
</template>
