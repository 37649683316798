import { defineStore } from 'pinia';
import { getAllVendors } from '@/common/api/getAllVendors';
import { getVendor } from '@/common/api/getVendor';
import { APIError } from '@/common/errors/APIError';
import { handleThrownError } from '@/common/errors/handleThrownError';
import type { OptionsType } from '@/components/shared/DeskSelect/types';
import { useToastStore } from '@/stores/useToastStore';
import type { VendorSchema } from '@/types';

interface VendorStoreState {
  vendors: OptionsType[];
  areVendorsLoaded: boolean;
  loadedVendor?: VendorSchema;
}

const transformData = (data: VendorSchema[]): OptionsType[] =>
  data.map((vendor) => ({
    id: vendor.id,
    name: vendor.name,
    value: vendor.id,
  }));

export const useVendorStore = defineStore('vendorStore', {
  state: (): VendorStoreState => ({
    vendors: [],
    areVendorsLoaded: false,
    loadedVendor: undefined,
  }),
  actions: {
    async loadVendors(shortname: string) {
      this.vendors.splice(0);
      try {
        const { status, data } = await getAllVendors(shortname);
        if (status !== 200 || !data) {
          throw new Error('Failed to load Vendors');
        }
        this.vendors.push(...transformData(data));
        this.areVendorsLoaded = true;
      } catch (e: unknown) {
        useToastStore().error('Failed to load Vendors');
      }
    },
    async loadVendor(shortname: string, vendorId: string) {
      try {
        const { status, data, error } = await getVendor(shortname, vendorId);
        if (status !== 200 || !data || error) {
          throw new APIError(
            'Failed to load Vendor',
            {
              status,
              vendorId,
            },
            error,
          );
        }
        this.loadedVendor = data;
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load Vendor',
          log: true,
        });
      }
    },
  },
});
