<script setup lang="ts">
import { computed } from 'vue';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import { FhButton } from '@fareharbor-com/beacon-vue';
import { postLogout } from '@/common/api/postLogout';
import { APIError } from '@/common/errors/APIError';
import { handleThrownError } from '@/common/errors/handleThrownError';
import CompanyNavigation from '@/components/companyNavigation/CompanyNavigation.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import { config } from '@/configs';
import { RouteNames } from '@/router/RouteNames';
import { useAuthStore } from '@/stores/useAuthStore';
import { DeskApplicationEnvironment } from '@/types/DeskApplicationEnvironment';

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

const showCompanyNavigation = computed(
  () =>
    !!route.matched.find(
      (matchedRoute) => matchedRoute.name === RouteNames.COMPANY,
    ),
);

const PRODUCT_PORTAL_URL =
  'https://portal.productboard.com/smnzvezpbkyo758ogmbzhnsm/tabs/20-collecting-feedback-on';

const handleLogout = async () => {
  try {
    const { status, error } = await postLogout();
    if (status !== 204 || error) {
      throw new APIError('Failed to logout', {}, error);
    }
  } catch (error) {
    handleThrownError({
      error,
      log: true,
    });
  }
  authStore.logout();
  router.push({
    name: RouteNames.LOGIN,
  });
};
</script>

<template>
  <DeskFlexbox justifyContent="space-between" class="bg-white h-12">
    <DeskFlexbox align-items="center">
      <RouterLink
        class="text-2xl self-center font-bold py-2 mr-4"
        :to="{ name: RouteNames.HOME }"
      >
        Desk
        <span
          class="text-red-600"
          v-if="config.ENVIRONMENT === DeskApplicationEnvironment.DEMO"
        >
          DEMO
        </span>
      </RouterLink>
      <CompanyNavigation v-if="showCompanyNavigation" />
    </DeskFlexbox>
    <DeskFlexbox align-self="center">
      <FhButton
        v-if="
          config.ENVIRONMENT === DeskApplicationEnvironment.LOCAL ||
          config.ENVIRONMENT === DeskApplicationEnvironment.PRODUCTION
        "
        variant="tertiary"
        :href="PRODUCT_PORTAL_URL"
      >
        Feedback
      </FhButton>
      <button
        class="text-sm font-medium text-center text-gray-500 inline-block p-3 rounded-lg hover:text-gray-600 hover:bg-gray-50"
        v-if="authStore.isLoggedIn"
        @click="handleLogout"
      >
        Logout
      </button>
    </DeskFlexbox>
  </DeskFlexbox>
</template>
