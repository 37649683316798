import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { CustomerTypeSchema } from '@/types';
import type { GenericResponse } from '.';

type GetCustomerTypeResponse = GenericResponse<CustomerTypeSchema>;

export const getCustomerType = async (
  shortname: string,
  productId: string,
  customerTypeId: string,
): Promise<GetCustomerTypeResponse> => {
  const { response, data, error } = await get(
    '/v1/companies/{shortname}/products/{product_id}/customer-types/{customer_type_id}',
    {
      params: {
        path: {
          shortname,
          product_id: productId,
          customer_type_id: customerTypeId,
        },
      },
      headers: getHeaders(),
    },
  );

  return {
    status: response.status,
    data,
    error,
  };
};
