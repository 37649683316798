import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { GetProductsQuery, PaginatedProductsSchema } from '@/types';
import type { GenericResponse } from '.';

type GetProductsResponse = GenericResponse<PaginatedProductsSchema>;

export const getProducts = async (
  shortname: string,
  vendorId: string,
  locationId?: string,
): Promise<GetProductsResponse> => {
  const query: GetProductsQuery = {
    vendor_id: vendorId,
  };
  if (locationId) {
    query.location_id = locationId;
  }
  const { response, data, error } = await get(
    '/v1/companies/{shortname}/products',
    {
      params: {
        path: {
          shortname,
        },
        query,
      },
      headers: getHeaders(),
    },
  );

  return {
    status: response.status,
    error,
    data,
  };
};
