import type { PaginatedVendorSchema, VendorSchema } from '@/types';
import type { GenericResponse } from '.';
import { getVendors } from './getVendors';
import { loadAllPages } from './loadAllPages';

type GetAllVendorsResponse = GenericResponse<VendorSchema[]>;

export const getAllVendors = async (
  shortname: string,
  locationId?: string,
): Promise<GetAllVendorsResponse> =>
  loadAllPages<VendorSchema, PaginatedVendorSchema>((offset: number) =>
    getVendors(shortname, offset, locationId),
  );
