import { defineStore } from 'pinia';
import { getCustomerType } from '@/common/api/getCustomerType';
import { getCustomerTypes } from '@/common/api/getCustomerTypes';
import { APIError } from '@/common/errors/APIError';
import { handleThrownError } from '@/common/errors/handleThrownError';
import type { OptionsType } from '@/components/shared/DeskSelect/types';
import { useToastStore } from '@/stores/useToastStore';
import type { CustomerTypeSchema } from '@/types';

const transformData = (data: CustomerTypeSchema[]): OptionsType[] =>
  data.map((customerType) => ({
    id: customerType.id,
    name: customerType.name,
    value: customerType.id,
  }));

interface CustomerTypeStoreState {
  customerTypes: OptionsType[];
  loadedCustomerType?: CustomerTypeSchema;
}

export const useCustomerTypeStore = defineStore('customerTypeStore', {
  state: (): CustomerTypeStoreState => ({
    customerTypes: [],
    loadedCustomerType: undefined,
  }),

  actions: {
    async loadCustomerTypes(shortname: string, productId: string) {
      this.customerTypes.splice(0);
      try {
        const { status, data } = await getCustomerTypes(shortname, productId);
        if (status !== 200 || !data) {
          throw new Error('Failed to load Customer Types');
        }
        this.customerTypes.push(...transformData(data.entities));
      } catch (e: unknown) {
        useToastStore().error('Failed to load Customer Types');
      }
    },
    async loadCustomerType(
      shortname: string,
      productId: string,
      customerTypeId: string,
    ) {
      try {
        const { status, data, error } = await getCustomerType(
          shortname,
          productId,
          customerTypeId,
        );

        if (status !== 200 || !data || error) {
          throw new APIError(
            'Failed to load Customer Type',
            {
              status,
              productId,
              customerTypeId,
            },
            error,
          );
        }
        this.loadedCustomerType = data;
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load Customer Type',
          log: true,
        });
      }
    },
  },
});
