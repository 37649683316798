import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { GetVendorsQuery, PaginatedVendorSchema } from '@/types';
import type { GenericResponse } from '.';

type GetVendorsResponse = GenericResponse<PaginatedVendorSchema>;

export const getVendors = async (
  shortname: string,
  offset?: number,
  locationId?: string,
): Promise<GetVendorsResponse> => {
  const query: GetVendorsQuery = {};

  if (offset) {
    query.offset = offset;
  }
  if (locationId) {
    query.location_id = locationId;
  }
  const { response, data, error } = await get(
    '/v1/companies/{shortname}/vendors',
    {
      params: {
        path: {
          shortname,
        },
        query,
      },
      headers: getHeaders(),
    },
  );

  return {
    status: response.status,
    data,
    error,
  };
};
