import type { GenericResponse } from '.';

interface PaginatedResponse<T> {
  count: number;
  entities: T[];
}

export const loadAllPages = async <
  GenericSchema,
  PaginatedGenericSchema extends PaginatedResponse<GenericSchema>,
>(
  fetchResponse: (
    offset: number,
  ) => Promise<GenericResponse<PaginatedGenericSchema>>,
): Promise<GenericResponse<GenericSchema[]>> => {
  const data: GenericSchema[] = [];
  let offset = 0;
  let hasMore = true;

  while (hasMore) {
    // eslint-disable-next-line no-await-in-loop
    const response = await fetchResponse(offset);
    if (!response.data || response.status !== 200 || response.error) {
      return {
        status: response.status,
        error: response.error,
      };
    }

    offset += response.data.entities.length;
    data.push(...response.data.entities);

    if (data.length >= response.data.count) {
      hasMore = false;
    }
  }

  return {
    status: 200,
    data,
  };
};
