import { defineStore } from 'pinia';
import { getProduct } from '@/common/api/getProduct';
import { getProducts } from '@/common/api/getProducts';
import { APIError } from '@/common/errors/APIError';
import { handleThrownError } from '@/common/errors/handleThrownError';
import type { OptionsType } from '@/components/shared/DeskSelect/types';
import type { ProductSchema } from '@/types';
import { useToastStore } from './useToastStore';

const transformData = (data: ProductSchema[]): OptionsType[] =>
  data.map((product) => ({
    id: product.id as string,
    name: product.name,
    value: product.id as string,
  }));

interface ProductStoreState {
  products: OptionsType[];
  loadedProduct?: ProductSchema;
}

export const useProductStore = defineStore('productStore', {
  state: (): ProductStoreState => ({
    products: [],
    loadedProduct: undefined,
  }),
  getters: {
    isOnlineProduct: (state) =>
      !!(
        state.loadedProduct?.external_id && state.loadedProduct?.external_source
      ),
  },
  actions: {
    async loadProducts(shortname: string, vendorId: string) {
      this.products.splice(0);
      try {
        const { status, data } = await getProducts(shortname, vendorId);
        if (status !== 200 || !data) {
          throw new Error('Failed to load Products');
        }
        this.products.push(...transformData(data.entities));
      } catch (e: unknown) {
        useToastStore().error('Failed to load Products');
      }
    },

    async loadProduct(shortname: string, productId: string) {
      try {
        const { status, data, error } = await getProduct(shortname, productId);

        if (status !== 200 || !data || error) {
          throw new APIError(
            'Failed to load Product',
            {
              status,
              productId,
            },
            error,
          );
        }
        this.loadedProduct = data;
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load Product',
          log: true,
        });
      }
    },
  },
});
