import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { VendorSchema } from '@/types';
import type { GenericResponse } from '.';

type GetVendorResponse = GenericResponse<VendorSchema>;

export const getVendor = async (
  shortname: string,
  vendorId: string,
): Promise<GetVendorResponse> => {
  const { response, data, error } = await get(
    '/v1/companies/{shortname}/vendors/{vendor_id}',
    {
      params: {
        path: {
          shortname,
          vendor_id: vendorId,
        },
      },
      headers: getHeaders(),
    },
  );

  return {
    status: response.status,
    data,
    error,
  };
};
