import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { PaginatedCustomerTypeSchema } from '@/types';
import type { GenericResponse } from '.';

type GetCustomerTypeResponse = GenericResponse<PaginatedCustomerTypeSchema>;

export const getCustomerTypes = async (
  shortname: string,
  productId: string,
): Promise<GetCustomerTypeResponse> => {
  const { response, data, error } = await get(
    '/v1/companies/{shortname}/products/{product_id}/customer-types',
    {
      params: {
        path: {
          shortname,
          product_id: productId,
        },
      },
      headers: getHeaders(),
    },
  );

  return {
    status: response.status,
    error,
    data,
  };
};
