import type { Router } from 'vue-router';
import { useAuthStore } from '@/stores/useAuthStore';
import { RouteNames } from './RouteNames';

export const setGuardForRouter = (router: Router) => {
  // Disabling consistent-return here as the router expects no default return
  // eslint-disable-next-line consistent-return
  router.beforeEach(async (to) => {
    const authStore = useAuthStore();

    const isDeskSelected =
      localStorage.getItem('deskId') && localStorage.getItem('deskName');
    const selectedCompany = localStorage.getItem('companyShortname');

    if (!authStore.isLoggedIn && !to.meta.isPublic) {
      return { name: RouteNames.LOGIN };
    }

    if (authStore.isLoggedIn && to.name === RouteNames.LOGIN) {
      return { name: RouteNames.DESK_SELECT };
    }

    if (authStore.isLoggedIn && to.name === RouteNames.HOME) {
      return { name: RouteNames.DESK_SELECT };
    }

    if (authStore.isLoggedIn && to.name === RouteNames.COMPANY_DASHBOARD) {
      if (to.params.shortname === selectedCompany) {
        return {
          name: RouteNames.CLIENTS,
          params: to.params,
        };
      }
      return { name: RouteNames.DESK_SELECT };
    }

    if (
      !isDeskSelected &&
      to.name !== RouteNames.DESK_SELECT &&
      to.path.includes('/company')
    ) {
      return { name: RouteNames.DESK_SELECT };
    }
  });

  router.afterEach(async (to) => {
    document.title = to.meta?.title ?? 'Desk';
  });
};
