import { computed, type ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { useCompanySettingsPermissions } from '@/composables/permissions/useCompanySettingsPermissions';
import { useUsersPermissions } from '@/composables/permissions/useUsersPermissions';
import { RouteNames } from '@/router/RouteNames';
import { useCompanyStore } from '@/stores/useCompanyStore';
import type { DropdownMenuItem } from './types';

export const useCompanyNavigation = (shortname: string) => {
  const router = useRouter();

  const { canReadUsers } = useUsersPermissions(shortname);
  const { canReadCompanySettings } = useCompanySettingsPermissions(shortname);
  const companyStore = useCompanyStore();

  const clientsRoute = router.resolve({
    name: RouteNames.CLIENTS,
    params: {
      shortname,
    },
  });

  const vendorSearchRoute = router.resolve({
    name: RouteNames.VENDOR_SEARCH,
    params: {
      shortname,
    },
  });

  const vendorsRoute = router.resolve({
    name: RouteNames.VENDORS,
    params: {
      shortname,
    },
  });

  const desksRoute = router.resolve({
    name: RouteNames.DESKS,
    params: {
      shortname,
    },
  });

  const hotelsRoute = router.resolve({
    name: RouteNames.HOTELS,
    params: {
      shortname,
    },
  });

  const locationsRoute = router.resolve({
    name: RouteNames.LOCATIONS,
    params: {
      shortname,
    },
  });

  const payTypesRoute = router.resolve({
    name: RouteNames.PAYMENT_TYPES,
    params: {
      shortname,
    },
  });

  const taxTypesRoute = router.resolve({
    name: RouteNames.TAX_TYPES,
    params: {
      shortname,
    },
  });

  const sourcesRoute = router.resolve({
    name: RouteNames.SOURCES,
    params: {
      shortname,
    },
  });

  const customFieldsRoute = router.resolve({
    name: RouteNames.CUSTOM_FIELDS,
    params: {
      shortname,
    },
  });

  const voucherRefundReasonsRoute = router.resolve({
    name: RouteNames.REFUND_REASONS,
    params: {
      shortname,
    },
  });

  const usersRoute = router.resolve({
    name: RouteNames.USERS,
    params: {
      shortname,
    },
  });

  const companyInformationRoute = router.resolve({
    name: RouteNames.COMPANY_SETTINGS_INFORMATION,
    params: {
      shortname,
    },
  });
  const companySettingsRoute = router.resolve({
    name: RouteNames.COMPANY_SETTINGS,
    params: {
      shortname,
    },
  });

  const bankAndPaymentsSettingsRoute = router.resolve({
    name: RouteNames.COMPANY_SETTINGS_BANK_AND_PAYMENTS,
    params: {
      shortname,
    },
  });

  const agentCommissionPoliciesRoute = router.resolve({
    name: RouteNames.AGENT_COMMISSION_POLICIES,
    params: {
      shortname,
    },
  });

  const affiliateRoute = router.resolve({
    name: RouteNames.AFFILIATES,
    params: {
      shortname,
    },
  });

  const settingsDropdownItems: ComputedRef<DropdownMenuItem[]> = computed(
    () => {
      const dropdownItems: DropdownMenuItem[] = [];

      if (canReadCompanySettings.value) {
        dropdownItems.push(
          {
            to: companyInformationRoute,
            label: 'Company Information',
          },
          {
            to: bankAndPaymentsSettingsRoute,
            label: 'Bank and Payments',
          },
        );

        dropdownItems.push({
          to: companySettingsRoute,
          label: 'Company Settings',
        });
      }

      if (canReadUsers.value) {
        dropdownItems.push({ to: usersRoute, label: 'Users' });
      }

      return dropdownItems;
    },
  );

  const maintenanceDropdownItems: ComputedRef<DropdownMenuItem[]> = computed(
    () => {
      const items: DropdownMenuItem[] = [
        { to: vendorsRoute, label: 'Vendors' },
        { to: sourcesRoute, label: 'Sources' },
        { to: payTypesRoute, label: 'Pay Types' },
        { to: taxTypesRoute, label: 'Tax Types' },
        { to: hotelsRoute, label: 'Hotels' },
        { to: desksRoute, label: 'Desks' },
        { to: locationsRoute, label: 'Locations' },
        { to: customFieldsRoute, label: 'Custom Fields' },
        { to: voucherRefundReasonsRoute, label: 'Refund Reasons' },
        {
          to: agentCommissionPoliciesRoute,
          label: 'Agent Commission Policies',
        },
      ];

      if (companyStore.isAffiliatesEnabled) {
        items.push({
          to: affiliateRoute,
          label: 'Affiliates',
        });
      }

      return items;
    },
  );

  const reportsRoute = router.resolve({
    name: RouteNames.REPORTS,
    params: {
      shortname,
    },
  });

  const activityLogRoute = router.resolve({
    name: RouteNames.ACTIVITY_LOG,
    params: {
      shortname,
    },
  });

  return {
    clientsRoute,
    vendorSearchRoute,
    settingsDropdownItems,
    maintenanceDropdownItems,
    reportsRoute,
    activityLogRoute,
  };
};
