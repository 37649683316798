import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { ProductSchema } from '@/types';
import type { GenericResponse } from '.';

type GetProductResponse = GenericResponse<ProductSchema>;

export const getProduct = async (
  shortname: string,
  productId: string,
): Promise<GetProductResponse> => {
  const { response, data, error } = await get(
    '/v1/companies/{shortname}/products/{product_id}',
    {
      params: {
        path: {
          shortname,
          product_id: productId,
        },
      },
      headers: getHeaders(),
    },
  );

  return {
    status: response.status,
    data,
    error,
  };
};
