import { getHeaders } from '@/common/getHeaders';
import { post } from '@/common/request';
import type { GenericResponse } from '.';

type PostLogoutResponse = GenericResponse<never>;

export const postLogout = async (): Promise<PostLogoutResponse> => {
  const { response, data, error } = await post('/v1/iam/auth/logout', {
    headers: getHeaders(),
  });

  return {
    status: response.status,
    data,
    error,
  };
};
